@font-face {
  font-family: 'Mars-Centra-Bold';
  src: local('Fonts'), url(./Fonts/MarsCentra-Bold.woff) format('opentype');
}

/*
@font-face {
  font-family: "Mars-Centra";

  src: local("Fonts"), url(./Fonts/MarsCentra-Bold.woff2) format("opentype");
} */

@font-face {
  font-family: 'Mars-Centra';

  src: local('Fonts'), url(./Fonts/MarsCentra-Book.woff) format('opentype');
}

* {
  font-family: 'Mars-Centra', sans-serif;
}

input[type='text'] {
  font-family: 'Mars-Centra', sans-serif;
}

textarea {
  font-family: 'Mars-Centra', sans-serif;
  font-size: 400;
}

a {
  text-decoration: none;
  color: inherit;
}
body {
  background: #f7f9fe;
  box-sizing: border-box;
}
.MuiSelect-select {
  font-size: 14px;
}

/* @font-face {
  font-family: "Mars-Centra";
  src: local("Fonts"), url(./Fonts/MarsCentra-Book.woff2) format("opentype");
}

@font-face {
  font-family: "Mars-Centra";
  src: local("Fonts"), url(./Fonts/MarsCentra-Extrabold.woff) format("opentype");
}

@font-face {
  font-family: "Mars-Centra";
  src: local("Fonts"),
    url(./Fonts/MarsCentra-Extrabold.woff2) format("opentype");
} */

@media (min-width: 481px) and (max-width: 1024px) {
  .MuiSelect-select {
    font-size: 12px !important;
  }
  .project-view {
    /* border: 1px solid red; */
    padding: 12px 28px !important;
  }
  .favorite-empty {
    margin-left: 28px !important;
  }
  .project-table-action1 {
    display: none !important;
  }
  .project-table-action2 {
    display: block;
  }
  .MuiTabs-flexContainer {
    font-size: 10px !important;
    line-height: 12px !important;
  }
  .MuiTabs-flexContainer button .tab-icon {
    width: 12px !important;
    height: 12px !important;
  }
}
@media (min-width: 1024px) and (max-width: 100vw) {
  .project-table-action1 {
    display: block;
  }
  .project-table-action2 {
    display: none !important;
  }
}
