body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Mars-Centra"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Mars-Centra";
} */
