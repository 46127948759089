.header {
  width: 100%;
  background: transparent;
  justify-content: space-between;
  padding: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
}
